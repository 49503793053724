import * as config from '../../../config';

const app = {
  state: {
    jgbm: config.APP_JGBM
  },
  mutations: {
    
  },
  actions: {
    
  },
};

export default app;