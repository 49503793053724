import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import getPageTitle from '../utils/get-page-title'
import * as Utils from '../utils';
import { clearCache } from '../utils/auth';

import showAuthDialog from '../utils/show-auth-dialog';
import { Dialog,Toast} from 'vant';
import { getOpenId, setOpenId } from '../utils/auth';
import {getWxOpenId } from '../api/login';

Vue.use(VueRouter)
const whiteList = ['Home','Login','NewsDetail','NotifyDetail','ErrorTip','404'];

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta:{
      title: '登录'
    },
    component: () => import('../views/Login/index.vue')
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页'
    },
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/my',
    name: 'My',
    meta:{
      title: '个人中心',
      requestAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "my" */ '../views/my/index.vue')
  },
  {
    path: '/dfzd',
    name: 'dfzd',
    meta: {
      title: '电费账单',
      requestAuth: true
    },
    component: () => import('../views/dfzw/dfzd/index.vue')
  },{
    path: '/sfqd',
    name: 'sfqd',
    meta: {
      title: '算费清单',
      requestAuth: true
    },
    component: () => import('../views/dfzw/sfqd/index.vue')
  },
  {
    path: '/dzfp/',
    name: 'Dzfp',
    meta: {
      title: '电子发票',
      requestAuth: true
    },
    component: () => import('../views/dfzw/dzfp/index.vue')
  },
  {
    path: '/hdjeRecord/',
    name: 'hdjeRecord',
    meta: {
      title: '合打金额明细',
      requestAuth: true
    },
    component: () => import('../views/dfzw/dzfp/hdje-record.vue')
  },
  {
    path: '/jfjl',
    name: 'jfjl',
    meta: {
      title: '缴费记录',
      requestAuth: true
    },
    component: () => import('../views/dfzw/jfjl/index.vue')
  },
  {
    path: '/invoice-title-edit',
    name: 'InvoiceTitleEdit',
    meta: {
      title: '发票抬头维护',
      requestAuth: true
    },
    component: () => import('../views/dfzw/dzfp/invoice-title-edit.vue')
  },
  {
    path: '/ywbl',
    name: 'Ywbl',
    meta: {
      title: '业务办理',
      requestAuth: true
    },
    component: () => import('../views/ywblmodule/index.vue')
  },
  {
    path: '/ydxz',
    name: 'ydxz',
    meta: {
      title: '用电新装申请',
      requestAuth: true
    },
    component: () => import('../views/ywblmodule/ydxz/index.vue')
  },
  {
    path: '/ydbg',
    name: 'ydbg',
    meta: {
      title: '用电变更申请',
      requestAuth: true
    },
    component: () => import('../views/ywblmodule/ydbg/index.vue')
  },
  {
    path: '/ydxh',
    name: 'ydxh',
    meta: {
      title: '用电销户申请',
      requestAuth: true
    },
    component: () => import('../views/ywblmodule/ydxh/index.vue')
  },
  {
    path: '/gdcx',
    name: 'gdcx',
    meta: {
      title: '工单查询',
      requestAuth: true
    },
    component: () => import('../views/ywblmodule/gdcx/index.vue')
  },
  {
    path: '/gdxx',
    name: 'gdxx',
    meta: {
      title: '工单详情',
      requestAuth: true
    },
    component: () => import('../views/ywblmodule/gdxx/index.vue')
  },
  {
    path: '/yhbh',
    name: 'Yhbh',
    meta: {
      title: '户号管理',
      requestAuth: true
    },
    component: () => import('../views/yhbh/index.vue')
  },
  {
    path: '/bind-mobile',
    name: 'BindMobile',
    meta: {
      title: '手机号绑定',
      requestAuth: true
    },
    component: () => import('../views/my/bind-mobile.vue')
  },
  {
    path: '/bind-yhbh',
    name: 'BindYhbh',
    meta: {
      title: '绑定户号',
      requestAuth: true
    },
    component: () => import('../views/yhbh/bind-yhbh.vue')
  },
  {
    path: '/yhbh-detail/:yhbh',
    name: 'YhbhDetail',
    meta: {
      title: '用电账户详情',
      requestAuth: true
    },
    component: () => import('../views/yhbh-detail/index.vue')
  },
  {
    path: '/gzbx',
    name: 'gzbx',
    meta: {
      title: '故障报修',
      requestAuth: true
    },
    component: () => import('../views/gzbx/index.vue')
  },
  {
    path: '/gzsb',
    name: 'gzsb',
    meta: {
      title: '故障报修',
      requestAuth: true
    },
    component: () => import('../views/gzbx/gzsb/index.vue')
  },
  {
    path: '/bxjlcx',
    name: 'bxjlcx',
    meta: {
      title: '报修记录查询',
      requestAuth: true
    },
    component: () => import('../views/gzbx/bxjlcx/index.vue')
  },
  {
    path: '/bxjlxq',
    name: 'bxjlxq',
    meta: {
      title: '报修记录详情',
      requestAuth: true
    },
    component: () => import('../views/gzbx/bxjlxq/index.vue')
  },
  {
    path: '/notify/:_id',
    name: 'NotifyDetail',
    mate: {
      title: '公告详情'
    },
    component: () => import('../views/home/notify-detail.vue')
  },
  {
    path: '/news/:_id',
    name: 'NewsDetail',
    mate: {
      title: '资讯详情'
    },
    component: () => import('../views/home/news-detail.vue')
  },
  {
    path: '/dfcj/:yhbh',
    name: 'Dfcj',
    mate: {
      title: '电费查缴'
    },
    component: () => import('../views/dfzw/dfcj/index.vue')
  },
  {
    path: '/ksjf',
    name: 'Ksjf',
    mate: {
      title: '快捷缴费'
    },
    component: () => import('../views/dfzw/ksjf/index.vue')
  },
  {
    path: '/dfcj/:yhbh/result',
    name: 'Dfcj',
    mate: {
      title: '缴费结果'
    },
    component: () => import('../views/dfzw/dfcj/success.vue')
  },
  {
    path: '/yfkcr/:yhbh',
    name: 'Yfkcr',
    mate: {
      title: '存入预付款'
    },
    component: () => import('../views/dfzw/yfkcr/index.vue')
  },
  {
    path: '/yfkcr/:yhbh/result',
    name: 'YfkcrResult',
    mate: {
      title: '预付款存入结果'
    },
    component: () => import('../views/dfzw/yfkcr/success.vue')
  },

  // 市场化交易模块
  {
    path: '/jydlsb',
    name: 'jydlsb',
    mate: {
      title: '交易电量申报'
    },
    component: () => import('../views/schjy/jydlsb/index.vue')
  },
  {
    path: '/sbjlcx',
    name: 'sbjlcx',
    mate: {
      title: '申报记录查询'
    },
    component: () => import('../views/schjy/sbjlcx/index.vue')
  },
  {
    path: '/schjy',
        name: 'schjy',
    mate: {
    title: '市场化交易'
    },
    component: () => import('../views/schjy/index.vue')
  },

  {
    path: '/pdf-viewer',
    name: 'PdfViewer',
    mate: {
      title: '发票预览'
    },
    component: () => import('../views/pdf-viewer/index.vue')
  },
  {
    path: '/dzhtgl',
    name: 'dzhtgl',
    mate: {
      title: '电子合同'
    },
    component: () => import('../views/dfzw/dzhtgl/index.vue')
  },
  {
    path: '/signature',
    name: 'signature',
    mate: {
      title: '电子签名'
    },
    component: () => import('../views/common/signature/index.vue')
  },
  {
    path: '/gfbw',
    name: 'gfbw',
    mate: {
      title: '光伏并网'
    },
    component: () => import('../views/gfbw/index.vue')
  },
  {
    path: '/bwgzsm',
    name: 'bwgzsm',
    mate: {
      title: '并网告知说明'
    },
    component: () => import('../views/gfbw/bwgzsm.vue')
  },
  {
    path: '/gfbwsq',
    name: 'gfbwsq',
    mate: {
      title: '光伏并网申请'
    },
    component: () => import('../views/gfbw/gfbwsq.vue')
  },
  {
    path: '/gfbwjlcx',
    name: 'gfbwjlcx',
    mate: {
      title: '并网申请记录'
    },
    component: () => import('../views/gfbw/gfbwjlcx.vue')
  },
  {
    path: '/error-tip',
    name: 'ErrorTip',
    meta: {
      title: '抱歉，出错了',
      errorMsg: '请在微信客户端打开链接',
      state: '0'
    },
    component: () => import('../views/error-tip.vue')
  },
  {
    path: "*",
    name: '404',
    meta: {
      title: '抱歉，出错了',
      errorMsg: '无法访问当前页面',
      state: '1'
    },
    component: () => import('../views/error-tip.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

let loadingInstance;

function loginErrNext(to, next) {
  if (to.query.code) {
    delete to.query.code;
  }

  if (whiteList.indexOf(to.name) > -1) {
    next(to);
  }
}

function registerByOpenId(to, from, next) {
  // 注册微信用户
  store.dispatch("registerByOpenId").then(res => {
    location.reload();
  }).catch(err => {
    Dialog.confirm({
      title: '登录失败',
      message: '登录失败：请刷新页面重试（Err='+err.message+")",
      showCancelButton: false,
      confirmButtonText: '重试'
    }).then(() => {
      next('/');
    });
  });
}

function loginWithOpenIdFunc(to, from, next) {
  const openId = getOpenId();
  store.dispatch('loginWithOpenId').then(res => {
    store.dispatch('getUserInfo').then(() => { 
      store.dispatch('getYhbhList');
      loadingInstance && loadingInstance.clear();
      Toast.success('登录成功！');
      next(to);
    }).catch(err => {
      next("/bind-mobile?autoback=1");
    });
  }).catch(err => {
    console.log(err);
    clearCache(); // 清除缓存
    loadingInstance && loadingInstance.clear();

    registerByOpenId(to, from, next);
  });
}

router.beforeEach(async(to, from, next) => {
  // set page title
  document.title = getPageTitle(to.meta.title)
  
  const loginToken = store.getters.token;

  let openId = getOpenId();
  let code = to.query.code || null;
  console.log("isWechat: ", Utils.isWechat());
  console.log("ENV == ", process.env)
  
  // if (process.env.NODE_ENV === 'production' && !Utils.isWechat() && to.path != '/error-tip') {
  //   next({path:'/error-tip', replace: true});
  //   return;
  // }
 
  if (to.path.indexOf("bind-mobile") > -1) {
    next();
    return;
  }
  
  if ( loginToken ) {
    if (!store.getters.dlzh) {
      store.dispatch('getUserInfo').then((userinfo) => {
        if (userinfo.yhsj) {
          next();
        } else {
          next("/bind-mobile?autoback=1");
        }
      }).catch(err => { // 未注册
        registerByOpenId(to, from, next);
        return;
      });
    } else {
      next();
    }
  // } else if (code == null && whiteList.indexOf(to.name) > -1) { // 白名单页面不需要登录，携带code时，需要主动换取 openId
  //   next();
  } else {
    // let openId = getOpenId();
    // let code = to.query.code || null;
    let t = Date.now();

    to.query.v = t;

    if (openId) {
      loadingInstance = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '登录中',
      });
      loginWithOpenIdFunc(to, from, next);
    } else {
      if (code) {
        // 通过 code 获取 openid 
        loadingInstance = Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '登录中',
        });
        getWxOpenId(code).then(res => {
          console.log("res********************"+res);
          if (res.status == 200) {
            setOpenId(res.data);
            loginWithOpenIdFunc(to, from, next);
          } else {
            throw new Error(res.message);
          }
        }).catch(err => {
          console.log(err);
          loadingInstance && loadingInstance.clear(); 
          clearCache(); // 清除缓存
          let errMsg = `通过 code 换取 openId 失败【${err.message}，code = ${code}】`;
                errMsg += ', 您可以点击下面的按钮尝试重新登录，或联系客服：0875-2207300';

          Dialog.confirm({
            title: '登录失败',
            message: errMsg,
            showCancelButton: false,
            confirmButtonText: '重试'
          }).then(() => {
            next('/');
          });
        });
        
      } else {
        const local = window.location.href;
        let uri = Utils.getWxOauthUrl(local);
        window.location.href = uri;
      }
    }
  }
});

export default router
