<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: 'APP',
    data()  {
      return {
      }
    },
    mounted() {
    },
    methods: {

    },
    created() {
      console.log("APP Lunch");
    },
  }

</script>

<style lang="scss">

@import '@/styles/common.scss';
@import '@/styles/custom-vant.scss';
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>
