import { WX_APP_ID ,REDIRECT_URI} from '../../config';

export function getWxOauthUrl(redirect_uri) {
  let t = Date.now();
  redirect_uri = redirect_uri.split("?")[0] + "?v="+ t;
  let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WX_APP_ID}&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=code&scope=snsapi_base&state=S#wechat_redirect`;
  
  return url;
}

export function isWechat () {
  let ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') != -1;
}

/**
 * 数字转中文大写
 * @param { number|string } n 
 * @returns 
 */
export function digitToChinese (n) {
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)){
    return "-";  //判断数据是否大于0
  }

  var unit = "仟佰拾亿仟佰拾万仟佰拾元角分", str = "";
  n += "00";  

  var indexpoint = n.indexOf('.');  // 如果是小数，截取小数点前面的位数

  if (indexpoint >= 0){
    n = n.substring(0, indexpoint) + n.substr(indexpoint+1, 2);   // 若为小数，截取需要使用的unit单位
  }

  unit = unit.substr(unit.length - n.length);  // 若为整数，截取需要使用的unit单位
  for (var i=0; i < n.length; i++){
    str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);  //遍历转化为大写的数字
  }

  return str.replace(/零(仟|佰|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整"); // 替换掉数字里面的零字符，得到结果
}

/**
 * 金额转中文大写
 * @param { number|string } n 
 * @returns 
 */
 export function currencyToChinese (n) {
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)){
    return "-";  //判断数据是否大于0
  }

  var unit = "仟佰拾亿仟佰拾万仟佰拾元角分", str = "";
  n += "00";  

  var indexpoint = n.indexOf('.');  // 如果是小数，截取小数点前面的位数

  if (indexpoint >= 0){

      n = n.substring(0, indexpoint) + n.substr(indexpoint+1, 2);   // 若为小数，截取需要使用的unit单位
  }

  unit = unit.substr(unit.length - n.length);  // 若为整数，截取需要使用的unit单位
  for (var i=0; i < n.length; i++){
      str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);  //遍历转化为大写的数字
  }

  return str.replace(/零(仟|佰|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整"); // 替换掉数字里面的零字符，得到结果
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  });
  return time_str
}
