<template>
  <div class="divider-box" :style="boxStyles"></div>
</template>

<script>
export default {
  name: 'DividerBox',
  props: {
    size: {
      type: [Number, String],
      default: 10
    },
    color: {
      type: String,
      default: '#f1f2f7'
    }
  },
  computed: {
    boxStyles() {
      const height = parseFloat(this.size);
      return {
        height: height + 'px',
        backgroundColor: this.color
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .divider-box {
    display: block;
    width: 100%;
  }
</style>