import fetch from '@/utils/fetch';

import { WX_APP_ID } from '../../config';

//互联网接口psd-ms-hlwjk：Service【无token的get请求方法，根据D:\psd\psd-ms-hlwjk\src\main\java\com\ydtf\psdmshlwjk\wxgzh\controller\WxgzhBasicsController.java手工编写如下】----------------------------------------------------------
/* ---
根据code获取openid
*/
export function getWxOpenId(code) {
    return fetch({
        url: 'psdmshlwjk/wxzhbasi/getopenid',
        method: 'get',
        params : {
          code: code
        }
    })
}

/**
 * 获取图形验证码
 * @param {string} phone
 */
export function getPhoneValidateCode(phone, timestamp) {
	return fetch({
		url: 'api/admin/admin/validateCodePhone',
		method: 'get',
		header: {
			'Content-Type': 'application/stream; charset=UTF-8'
		},
		responseType: 'arraybuffer',  //设置响应类型
		params: {
			phone: phone,
			timestamp: timestamp
		}
	})
}

/**
 * 获取短信验证码
 * @param {*} phone 
 * @param {*} code 
 * @returns 
 */
export function getPhoneCode(phone, code) {
  return fetch({
    url: 'api/admin/admin/getPhoneCode',
    method: 'post',
    data: {
      phone,
      code
    }
  });
}

export function addZcyhByOpenid(openId) {
  return fetch({
    url: 'psdmsqxgl/addZcyhByOpenid',
    method: 'get',
    params: {
      openid: openId,
      // appid: WX_APP_ID
    }
  })
}

//权限管理psd-ms-qxgl：service【无token的get请求方法，根据D:\psd\psd-ms-qxgl\src\main\java\com\ydtf\psdmsqxgl\controller\WXGZHController.java，结合原生D:\psd\psd-ms-qxgl-ui\generated\apis\psdmsqxgl\services\WXGZHServiceAPI.js修改如下】----------------------------------------------------------------
export function loginWithOpenId(openId) {
  return fetch({
    url: 'psdmsqxgl/getTokenByOpenid',
    method: 'get',
    params: {
      openid: openId
    }
  })
}
//网上营业厅无token根据用户手机登录并获取系统token信息-wsj20211109
export function loginWithYhsj(yhsj,sjyzm) {
  return fetch({
    url: 'psdmsqxgl/getTokenByYhsj',
    method: 'get',
    params: {
      yhsj: yhsj, //String
      sjyzm: sjyzm //String
    }
  })
}
//网上营业厅无token根据机构编码获取互联网发布信息-wsj20211105
export function getHlwFbxxByJgbm(jgbm,xxlb,xxzl,fbbzwxh,fbbzapp,fbbzyyt) {
  return fetch({
    url: 'psdmsqxgl/getHlwFbxxByJgbm',
    method: 'get',
    params: {
      jgbm: jgbm, //String
      xxlb: xxlb, //String
      xxzl: xxzl, //String
      fbbzwxh: fbbzwxh, //String
      fbbzapp: fbbzapp, //String
      fbbzyyt: fbbzyyt //String
    }
  })
}
//网上营业厅无token根据机构编码获取互联网发布分页信息-wsj20211111
export function getHlwFbxxPageByJgbm(jgbm,xxlb,xxzl,fbbzwxh,fbbzapp,fbbzyyt,orderBy,pageNum,pageSize) {
  return fetch({
    url: 'psdmsqxgl/getHlwFbxxPageByJgbm',
    method: 'get',
    params: {
      jgbm: jgbm, //String
      xxlb: xxlb, //String
      xxzl: xxzl, //String
      fbbzwxh: fbbzwxh, //String
      fbbzapp: fbbzapp, //String
      fbbzyyt: fbbzyyt, //String
      orderBy: orderBy, //String
      pageNum: pageNum, //Integer
      pageSize: pageSize //Integer
    }
  })
}
//网上营业厅无token根据信息ID获取互联网发布信息-wsj20211105
export function getHlwFbxxByXxid(xxid) {
  return fetch({
    url: 'psdmsqxgl/getHlwFbxxByXxid',
    method: 'get',
    params: {
      xxid: xxid //Long
    }
  })
}

// /* ---摘自原生WXGZHServiceAPI.js【此方法是代码生成器生成的原生post方法，不能供前端调用】
// 通过openid获取系统内的token
// */
// export function wXGZHService_getTokenByOpenid(openid, meta) {
//     return fetch({
//         url : 'psdmsqxgl/services/WXGZHService/getTokenByOpenid',
//         method : 'post',
//         headers : {
//         },
//         data : {
//             param : {
//                 openid : openid //String
//             }
//         }
//     })
// }

//自定义示例无实际意义-----------------------------------------------------------
// export function bindMobile(mobile) {
//   return fetch({
//     url: '/user/bind-mobile',
//     method: 'post',
//     data: {mobile}
//   })
// }
//
// export function bindYhbh(mobile) {
//   return fetch({
//     url: '/user/bind-yhbh',
//     method: 'post',
//     data: {mobile}
//   })
// }