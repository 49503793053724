import * as config from '../../config'

const getters = {
  appJgbm: state => state.app.jgbm,
  jgbm: state => state.user.jgbm || config.DEFAULT_JGBM,
  dybm: state => state.user.dybm,
  czyh: state => state.user.czyh || config.DEFAULT_CZYH,
  token: state => state.user.token,
  openId: state => state.user.openId,
  dlzh: state => state.user.dlzh,
  yhid: state => state.user.yhid,
  phone: state => state.user.yhsj,
  yhsj: state => state.user.yhsj,
  yhbhList: state => state.user.yhbhList,
  bindYxyh: state => state.user.bindYxyh,
};

export default getters
