<template>
  <div class="page" :class="customClass" :style="pageStyle">
    <div class="page-header" v-if="$slots.header || title" :style="headerStyle">
      <slot name="header" v-if="$slots.header"></slot>
      <van-nav-bar v-else :title="title" :left-arrow="leftArrow" :border="navBorder" @click-left="onClickLeft">
        <template #right v-if="$slots['nav-right']">
          <slot name="nav-right"></slot>
        </template>
      </van-nav-bar>
    </div>
    <div class="page-body" :style="bodyStyle">
      <slot></slot>
    </div>
    <div class="page-view--navbar" v-if="tabbar">
      <van-tabbar v-model="activeIndex">
        <van-tabbar-item replace to="/" icon="home-o">主页</van-tabbar-item>
        <van-tabbar-item replace to="/my" icon="user-o">个人中心</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { NavBar,  Tabbar, TabbarItem} from 'vant'
import Logo from '../Logo'
import router from '@/router'
export default {
  name: 'PageView',
  props: {
    header: {},
    title: String,
    leftArrow: {
      type: Boolean,
      default: false
    },
    navBorder: {
      type: Boolean,
      default: true
    },
    customClass: String,
    headerStyle: {},
    bodyStyle: {},
    tabbar: {
      type: Boolean,
      default: false
    },
    whiteBg: {
      type: Boolean,
      default: false
    }
  },
  components:{
    Logo,
    [NavBar.name]: NavBar,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
    }
  },
  computed: {
    activeIndex: {
      get() {
        let index = -1;
        switch (this.$route.name) {
          case "Home":
            index = 0;
            break;
          case "My":
            index = 1;
          default:
            break;
        }
        return index;
      },
      set() { }
    },
    pageStyle() {
      if (this.whiteBg) {
        return { backgroundColor: '#fff'}
      } else {
        return {}
      }
    }
  },
  methods: {
    onClickLeft() {
      if (window.history.length) {
        return router.back(-1)
      }
      router.push('/')
    }
  },
  mounted() {
    console.log(this.$slots)
  },
}
</script>

<style lang="scss" scoped>
  .page /deep/ {
    height: 100%;
    display: flex;
    flex-direction: column;
    .page-header {
      flex: 0 1 auto;
    }
    // .van-nav-bar__right {
    //   padding-right: 0;
    // }

    .page-title {
      height: 44px;
      line-height: 44px;
      font-size: 18px;
      font-weight: bold;
      margin: 16px 0;
    }

    .page-body {
      flex: 1;
      overflow: hidden;
      overflow-y: auto;
    }
  }
</style>