import fetch from '@/utils/fetch';

//权限管理psd-ms-qxgl：schema【有token的post请求方法，摘自原生D:\psd\psd-ms-qxgl-ui\generated\apis\psdmsqxgl\qxglschema\HlwZcyhAPI.js、HlwYxyhAPI.js、HlwFbxxAPI.js】----------------------------------------------------------------
/* ---
查询微信公众号注册用户
*/
export function hlwZcyh_getZcyhByOpenid(openid, meta) {
    return fetch({
        url : 'psdmsqxgl/qxglschema/HlwZcyh/getZcyhByOpenid',
        method : 'post',
        headers : {
        },
        data : {
            param : {
                openid : openid //String
            }
        }
    })
}

/* ---
微信公众号用户取消关注
*/
export function hlwZcyh_updateZcyhDqztFromWxgzh(openid, pagination, meta) {
    return fetch({
        url : 'psdmsqxgl/qxglschema/HlwZcyh/updateZcyhDqztFromWxgzh',
        method : 'post',
        headers : {
        },
        data : {
            param : {
                openid : openid //String
            },
            pagination : pagination
        }
    })
}

/* ---
查询微信公众号绑定营销用户
*/
export function hlwYxyh_getYxyhByOpenid(openid, pagination, meta) {
    return fetch({
        url : 'psdmsqxgl/qxglschema/HlwYxyh/getYxyhByOpenid',
        method : 'post',
        headers : {
        },
        data : {
            param : {
                openid : openid //String
            },
            pagination : pagination
        }
    })
}

/* ---
微信公众号用户解绑户号
*/
export function hlwZcyh_updateYxyhBdztFromWxgzh(yhbh,openid, pagination, meta) {
    return fetch({
        url : 'psdmsqxgl/qxglschema/HlwYxyh/updateYxyhBdztFromWxgzh',
        method : 'post',
        headers : {
        },
        data : {
            param : {
                yhbh : yhbh, //String
                openid : openid //String
            },
            pagination : pagination
        }
    })
}
/* ---
微信公众号默认选择户号
*/
export function hlwYxyh_updateYxyhMrxzbzFromWxgzh(yhbh,openid, pagination, meta) {
    return fetch({
        url : 'psdmsqxgl/qxglschema/HlwYxyh/updateYxyhMrxzbzFromWxgzh',
        method : 'post',
        headers : {
        },
        data : {
            param : {
                yhbh : yhbh, //String
                openid : openid //String
            },
            pagination : pagination
        }
    })
}

/* ---
根据微信公众号openid查询互联网发布信息
*/
export function hlwFbxx_getFbxxByOpenid(openid,xxlb, pagination, meta) {
    return fetch({
        url : 'psdmsqxgl/qxglschema/HlwFbxx/getFbxxByOpenid',
        method : 'post',
        headers : {
        },
        data : {
            param : {
                openid : openid, //String
                xxlb : xxlb //String
            },
            pagination : pagination
        }
    })
}

//权限管理psd-ms-qxgl：service【有token的post请求方法，摘自原生D:\psd\psd-ms-qxgl-ui\generated\apis\psdmsqxgl\services\WXGZHServiceAPI.js】----------------------------------------------------------------
/* ---
向用户手机发送微信公众号短信验证码
*/
export function wXGZHService_sendWxgzhDxyzm(yhsj,openid, meta) {
    return fetch({
        url : 'psdmsqxgl/services/WXGZHService/sendWxgzhDxyzm',
        method : 'post',
        headers : {
        },
        data : {
            param : {
                yhsj : yhsj, //String
                openid : openid //String
            }
        }
    })
}

/* ---
绑定用户手机号和营销户号
*/
export function wXGZHService_addYhsjhAndYxhh(openid,yhsj,sjyzm,yhbh, meta) {
    return fetch({
        url : 'psdmsqxgl/services/WXGZHService/addYhsjhAndYxhh',
        method : 'post',
        headers : {
        },
        data : {
            param : {
                openid : openid, //String
                yhsj : yhsj, //String
                sjyzm : sjyzm, //String
                yhbh : yhbh //String
            }
        }
    })
}


