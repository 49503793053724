<template>
  <div class="logo">
    <img :src="logoImg" class="logo-img" :style="{marginLeft: '-5px'}"/>
  </div>
</template>

<script>
import logoImg_1R6 from '@/assets/logo-1R6.png';
import logoImg_1R from '@/assets/logo-1R.png';
import { mapGetters } from "vuex";
export default {
  name: 'Logo',
  data() {
    return {
      logoImg_1R6,
      logoImg_1R
    }
  },
  computed: {
    ...mapGetters(['appJgbm']),
    logoImg() {
      switch(this.appJgbm) {
        case '1R':
          return this.logoImg_1R;
          break;
        case '1R6':
        default:
          return this.logoImg_1R6;
          break;
      } 
    }
  },
}
</script>

<style lang="scss" scoped>
.logo /deep/{
  display: flex;
  align-items: center;
  .logo-img {
    height: 36px;
  }

  img {
    image-rendering: -moz-crisp-edges; /* Firefox */     
    image-rendering: -o-crisp-edges; /* Opera */      
    image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */ 
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }
}

</style>

